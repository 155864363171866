const Wallpapers = {
  list: [
    {
      name: '9fa80fd805562a6bc817f01f48b8b93e.jpg',
      description: 'Wharariki Beach Cave, Archway Islands, South Island of New Zealand',
    },
    {
      name: 'd2luMTBfb2ZmaWNpYWxfd2FsbHBhcGVy.jpg',
      description: 'Windows 10 Official wallpaper',
    },
  ],
  defaultWallpaper: 1,
};

export default Wallpapers;
